import { render, staticRenderFns } from "./DeleteUserInfoPage.vue?vue&type=template&id=9a10a88e&scoped=true&"
import script from "./DeleteUserInfoPage.vue?vue&type=script&lang=js&"
export * from "./DeleteUserInfoPage.vue?vue&type=script&lang=js&"
import style0 from "./DeleteUserInfoPage.vue?vue&type=style&index=0&id=9a10a88e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a10a88e",
  null
  
)

export default component.exports