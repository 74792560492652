<template>
  <b-card class="m-4 p-4">
    <div class="m-0 bg-gray h-100">
      <b-row>
        <b-col
          class="h-100 w-100 d-flex justify-content-center align-items-center"
          md="12"
        >
          <h1 class="mt-4">
            SIMI Digital Delete User Info
          </h1>
        </b-col>
      </b-row>

      <!-- Section for displaying delete user info -->
      <b-row class="mt-4">
        <b-col md="12">
          <b-card
            title="Delete User Information"
            class="mb-4 m-2"
          >
            <p>
              This page provides information on how to delete user information from the system.
              Please review the following details carefully before proceeding.
            </p>
            <ul class="step-list">
              <li class="step-item pb-1">
                <p><strong>Step 1:</strong> Login to Simi Account.</p>
                <img
                  src="@/assets/images/landPageImg/Login.png"
                  alt="Step 1 Image"
                  class="step-image"
                >
              </li>
              <li class="step-item pb-1">
                <p><strong>Step 2:</strong> If you have any businesses. Go to business and click <strong>Cancel Subscription</strong> before deletion.</p>
                <img
                  src="@/assets/images/landPageImg/unsubscribe.png"
                  alt="Step 2 Image"
                  class="step-image"
                >
              </li>
              <li class="step-item pb-1">
                <p><strong>Step 3:</strong> Go to <strong>Settings</strong> after you finished unsubscribing.</p>
                <img
                  src="@/assets/images/landPageImg/settingLogo.png"
                  alt="Step 3 Image"
                  class="step-image"
                >
              </li>
              <li class="step-item">
                <p><strong>Step 4:</strong> Click <strong>Delete User Tab</strong> then click <strong>Delete</strong> button and confirm <strong>Delete</strong> to Delete User.</p>
                <img
                  src="@/assets/images/landPageImg/DeleteScreen.png"
                  alt="Step 4 Image"
                  class="step-image"
                >
              </li>
            </ul>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BRow, BCol,
  BCard,
} from 'bootstrap-vue'
import 'animate.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      data: {},
    }
  },
  methods: {
    confirmDelete() {
      // Add logic to confirm and delete user info
    //   if (confirm('Are you sure you want to delete this user information?')) {
    //     this.deleteUserInfo()
    //   }
    },
    deleteUserInfo() {
      // Add logic to delete user info
    //   alert('User information has been deleted.')
    },
  },
}
</script>

<!-- background-color: #f8f9fa; -->
  <style lang="scss" scoped>
  .bg-gray {
    background-color: #f3f4fd;
  }

  .pdf-viewer-placeholder {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9ecef;
    border: 1px dashed #ced4da;
    border-radius: 4px;
    color: #6c757d;
  }
  .step-list {
    list-style: none;
    padding: 0;
  }

  .step-item {
    margin-bottom: 1rem;
  }

  .step-image {
    max-width: 800px;
    max-height: 400px; /* Ensure the image scales with the container */
    margin-top: 0.5rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  </style>
